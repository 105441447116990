import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { CategoryScale } from 'chart.js'
import { DatePicker, Form, ConfigProvider, Select, Space, Button } from 'antd'
import { ApiOutlined, DashboardOutlined, DollarOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons'
import { formatCurrency, formatNumber } from '../../utils/string-utils'
import dayjs from 'dayjs'
import vi_VN from 'antd/es/locale/vi_VN'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import 'dayjs/locale/vi'
import ChartConnectByMonth from '../../components/chart/ChartConnectByMonth'
import ChartType from '../../components/chart/ChartType'
import ChartReturn from '../../components/chart/ChartReturn'
import { useCallback, useEffect, useState } from 'react'
import ChartConnectByDay from '../../components/chart/ChartConnectByDay'
import ChartRevenue from '../../components/chart/ChartRevenue'
import {
    apiGetListCompanies,
    apiGetListUsers,
    apiGetRevenueConnection,
    apiGetStatisticalDateConnection,
    apiGetStatisticalPackDurationConnection,
    apiGetStatisticalTypeConnection,
} from '../../api'
import defaultParams, {
    REVENUE_CONNECTION_AMOUNT_TYPE_PROFIT,
    REVENUE_CONNECTION_AMOUNT_TYPE_REVENUE,
    STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_FILTER,
    STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_RECORD,
    TRANSACTION_CONNECTION_TYPE_CONNECTION,
    TRANSACTION_CONNECTION_TYPE_EXTENSION,
} from '../../assets/constants/default_params'
import { useLoading } from '../../context/LoadingContext'
import { ICompany } from '../../models/company.modal'
import { filterOptionByLabel } from '../../utils/filter-utils'
import { IUser } from '../../models/user.model'
import { ADMIN_USERNAME } from '../../config/constants'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.locale('vi')

const { RangePicker } = DatePicker

Chart.register(CategoryScale, ChartDataLabels)

export default function DashboardDauNoi() {
    const [totalTypes, setTotalTypes] = useState<any>({ dauMoi: 0, giaHan: 0 })
    const [totalProfit, setTotalProfit] = useState<any>({ total: 0, dataPacks: [] })
    const [totalRevenue, setTotalRevenue] = useState<any>({})
    const [totalRecord, setTotalRecord] = useState<any>([])
    const [totalFilter, setTotalFilter] = useState<any>([])
    const [totalPeriod, setTotalPeriod] = useState<any>([])
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [userOptions, setUserOptions] = useState<{ label: string, value: Number }[]>([])
    const [search, setSearch] = useState<any>({})
    const [defaultSearch, setDefaultSearch] = useState<any>()
    const [formSearch] = Form.useForm()
    const { setIsLoading } = useLoading()

    const fetchOptions = useCallback(async () => {
        try {
            const [respCompany, respUser] = await Promise.all([
                apiGetListCompanies({
                    limit: defaultParams.MAX_LIMIT,
                }),
                apiGetListUsers({
                    limit: defaultParams.MAX_LIMIT,
                }),
            ])
            if (respCompany.data?.companies) {
                const compOptions = respCompany.data.companies
                    .map((item: ICompany) => ({
                        label: item.name,
                        value: item.code,
                    }))
                setCompanyOptions(compOptions)
            }
            if (respUser.data?.users) {
                const userOptions = respUser.data.users
                    .filter((item: IUser) => item.username !== ADMIN_USERNAME)
                    .map((item: IUser) => ({
                        label: item.username,
                        value: item.username,
                    }))
                setUserOptions(userOptions)
            }
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchStatistics = useCallback(async () => {
        try {
            setIsLoading(true)
            const [respType, respProfit, respRevenue, respRecord, respFilter, respPeriod] = await Promise.all([
                apiGetStatisticalTypeConnection({
                    amountType: STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_RECORD,
                    ...search,
                }),
                apiGetRevenueConnection({
                    amountType: REVENUE_CONNECTION_AMOUNT_TYPE_PROFIT,
                    ...search,
                }),
                apiGetRevenueConnection({
                    amountType: REVENUE_CONNECTION_AMOUNT_TYPE_REVENUE,
                    ...search,
                }),
                apiGetStatisticalDateConnection({
                    amountType: STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_RECORD,
                    ...search,
                }),
                apiGetStatisticalDateConnection({
                    amountType: STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_FILTER,
                    ...search,
                }),
                apiGetStatisticalPackDurationConnection({
                    amountType: STATISTICAL_CONNECTION_AMOUNT_TYPE_START_DATE_RECORD,
                    ...search,
                }),
            ])
            if (respType.data?.types != null) {
                let dauMoi = 0, giaHan = 0
                respType.data.types.map((item: any) => {
                    if (item.type === TRANSACTION_CONNECTION_TYPE_CONNECTION) {
                        dauMoi = item.sum
                    }
                    if (item.type === TRANSACTION_CONNECTION_TYPE_EXTENSION) {
                        giaHan = item.sum
                    }
                    return true
                })
                setTotalTypes({ dauMoi, giaHan })
            } else {
                setTotalTypes({ dauMoi: 0, giaHan: 0 })
            }
            if (respProfit.data !== null) {
                setTotalProfit(respProfit.data)
            } else {
                setTotalProfit({ total: 0, dataPacks: [] })
            }
            if (respRevenue.data !== null) {
                setTotalRevenue(respRevenue.data)
            } else {
                setTotalRevenue({})
            }
            if (respRecord.data?.months !== null) {
                setTotalRecord(respRecord.data.months)
            } else {
                setTotalRecord([])
            }
            if (respFilter.data?.months !== null) {
                setTotalFilter(respFilter.data.months)
            } else {
                setTotalFilter([])
            }
            if (respPeriod.data?.packDurations !== null) {
                setTotalPeriod(respPeriod.data.packDurations)
            } else {
                setTotalPeriod([])
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, search])

    useEffect(() => {
        const now = new Date()
        const year = now.getFullYear()
        const defaultParams = {
            cpCode: '',
            connectionType: '',
            numberMonth: '',
            startDate: `${year}-01-01`,
            endDate: `${year}-12-31`,
        }
        setDefaultSearch(defaultParams)
        setSearch(defaultParams)
    }, [])

    useEffect(() => {
        fetchOptions()
        if (defaultSearch) {
            fetchStatistics()
        }
    }, [fetchOptions, fetchStatistics, defaultSearch])

    const handleSearch = (values: any) => {
        const rangeDate = values?.dateConnect
        if (rangeDate) {
            const startDate = rangeDate[0] ? rangeDate[0]?.format('YYYY-MM-DD') : null
            const endDate = rangeDate[1] ? rangeDate[1]?.format('YYYY-MM-DD') : null
            values.startDate = startDate
            values.endDate = endDate
        }
        setSearch({
            cpCode: values.cpCode,
            connectionType: values.type,
            numberMonth: values.numberOfMonths,
            startDate: values.startDate || defaultSearch.startDate,
            endDate: values.endDate || defaultSearch.endDate,
        })
    }

    const handleClearSearch = () => {
        setSearch(defaultSearch)
        formSearch.resetFields()
    }

    return (
        <div>
            <Form
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'center',
                    marginBottom: 20,
                }}
                form={formSearch} onFinish={handleSearch} id="searchForm" layout="vertical" autoComplete="off">
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    flexWrap: 'wrap',
                    gap: 20,
                    width: '100%',
                }}>
                    <Form.Item name="cpCode" label={<b>Đối tác</b>}>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn đối tác"
                            defaultValue={''}
                            options={[
                                { label: 'Tất cả', value: '' },
                                ...companyOptions,
                            ]}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                    {defaultSearch && (
                        <ConfigProvider locale={vi_VN}>
                            <Form.Item name="dateConnect" label="Ngày đấu nối">
                                <RangePicker
                                    defaultValue={[dayjs(defaultSearch.startDate), dayjs(defaultSearch.endDate)]}
                                    format="YYYY-MM-DD"
                                    picker="date" />
                            </Form.Item>
                        </ConfigProvider>
                    )}
                    <Form.Item name="type" label="Phân loại">
                        <Select
                            style={{ width: 170 }}
                            defaultValue={''}
                            options={[
                                { label: 'Tất cả', value: '' },
                                { label: 'Đấu mới', value: TRANSACTION_CONNECTION_TYPE_CONNECTION },
                                { label: 'Gia hạn', value: TRANSACTION_CONNECTION_TYPE_EXTENSION },
                            ]} />
                    </Form.Item>
                    <Form.Item name="numberOfMonths" label="Chu kỳ">
                        <Select
                            style={{ width: 170 }}
                            defaultValue={0}
                            options={[
                                { label: 'Tất cả', value: 0 },
                                { label: '1 tháng', value: 1 },
                                { label: '12 tháng', value: 12 },
                            ]} />
                    </Form.Item>
                    {/* <Form.Item name='ownerBy' label='Nhân sự'>
                        <Select
                            style={{ minWidth: 170 }}
                            showSearch
                            placeholder="Chọn nhân sự"
                            defaultValue={''}
                            options={[
                                { label: 'Tất cả', value: '' },
                                ...userOptions
                            ]}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item> */}
                    <Space>
                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>Tìm kiếm</Button>
                        <Button onClick={handleClearSearch} icon={<UndoOutlined />}></Button>
                    </Space>
                </div>
            </Form>
            <div style={{ display: 'flex', justifyContent: 'center', gap: 40, marginBottom: 20 }}>
                <div style={{
                    textAlign: 'center',
                    fontSize: 18,
                    fontWeight: 700,
                    border: '2px dashed #ec4969',
                    padding: '8px 20px',
                    borderRadius: 10,
                }}>
                    <ApiOutlined /> Đấu mới: <span style={{
                        fontSize: 20,
                        color: '#ec4969',
                    }}>{formatNumber(totalTypes.dauMoi)}</span>
                </div>
                <div style={{
                    textAlign: 'center',
                    fontSize: 18,
                    fontWeight: 700,
                    border: '2px dashed #ec4969',
                    padding: '8px 20px',
                    borderRadius: 10,
                }}>
                    <DashboardOutlined /> Gia hạn: <span style={{
                        fontSize: 20,
                        color: '#ec4969',
                    }}>{formatNumber(totalTypes.giaHan)}</span>
                </div>
                <div style={{
                    textAlign: 'center',
                    fontSize: 18,
                    fontWeight: 700,
                    border: '2px dashed #ec4969',
                    padding: '8px 20px',
                    borderRadius: 10,
                }}>
                    <DollarOutlined /> Lợi nhuận: <span style={{
                        fontSize: 20,
                        color: '#ec4969',
                    }}>{formatCurrency(totalProfit.total)}</span>
                </div>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                {totalRecord.length > 0 && totalFilter.length > 0 && (
                    <div style={{ marginBottom: 40, flex: 2, minWidth: 500, maxHeight: 400 }}>
                        <ChartConnectByMonth
                            record={totalRecord}
                            filter={totalFilter}
                        />
                    </div>
                )}
                {totalPeriod.length > 0 && (
                    <div style={{ marginBottom: 40, flex: 2, minWidth: 500, maxHeight: 400 }}>
                        <ChartType
                            totalPeriodByMonth={totalPeriod}
                        />
                    </div>
                )}
                {totalProfit.dataPacks?.length > 0 && (
                    <div style={{ marginBottom: 40, flex: 1, maxWidth: 400, maxHeight: 400 }}>
                        <ChartReturn
                            profit={totalProfit}
                        />
                    </div>
                )}
                {totalRevenue.dataPacks?.length > 0 && (
                    <div style={{ marginBottom: 40, flex: 1, maxWidth: 400, maxHeight: 400 }}>
                        <ChartRevenue
                            revenue={totalRevenue}
                        />
                    </div>
                )}
                {totalRecord.length > 0 && (
                    <div style={{ marginBottom: 40, flex: 3 }}>
                        <ChartConnectByDay
                            connect={totalRecord}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}
