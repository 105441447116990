import { Button, Divider, Form, Input, message, Modal, Select, Space, TableProps, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useCallback, useEffect, useState } from 'react'
import { useLoading } from '../../context/LoadingContext'
import { toastError, toastSuccess } from '../../utils/toast'
import { CheckOutlined } from '@ant-design/icons'
import { apiAddTransactionConnection, apiGetListCompanies, apiGetListPackagesConnection, apiGetListTransactionConnection } from '../../api'
import defaultParams from '../../assets/constants/default_params'
import { ICompany } from '../../models/company.modal'
import { filterOptionByLabel } from '../../utils/filter-utils'
import * as XLSX from 'xlsx'
import CustomTable from '../../components/table'
import { Link } from 'react-router-dom'
import { transactionConnectionColumns } from './method'
import { IPartnerPackage } from '../../models/package.modal'

const rPhoneSim = /^(169|0169)?\d{7}$/
const rSerialSim = /^\d{19}$/
const rPhoneNumber = /^(0|84)?\d{9}$/
const rSpace = /\s+/

export default function Connect() {
    const [companyOptions, setCompanyOptions] = useState<{ label: string, value: Number }[]>([])
    const [partnerPackageOptions, setPartnerPackageOptions] = useState<{ label: string, value: Number }[]>([])
    const [transactionConnection, setTransactionConnection] = useState<any>({})
    const [currentPage, setCurrentPage] = useState<number>(defaultParams.PAGE)
    const [pageSize, setPageSize] = useState<number>(defaultParams.LIMIT)
    const [typeConnect, setTypeConnect] = useState<Number>(1)
    const [fileExcel, setFileExcel] = useState<any>()
    const [fileList, setFileList] = useState([])
    const [dataExcel, setDataExcel] = useState<any>(null)
    const [previewFileExcel, setPreviewFileExcel] = useState(false)
    const [modalStatus, setModalStatus] = useState<{ data: any | null, isOpen: boolean }>({
        data: null,
        isOpen: false,
    })
    const [cpCode, setCpCode] = useState<string>('')
    const { setIsLoading } = useLoading()
    const [formConnect] = Form.useForm()

    const fetchTransactionConnection = useCallback(async () => {
        try {
            setIsLoading(true)
            const resp = await apiGetListTransactionConnection({
                page: currentPage,
                size: pageSize,
            })
            if (resp.status === 200) {
                setTransactionConnection(resp.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }, [setIsLoading, currentPage, pageSize])

    const fetchCompanies = useCallback(async () => {
        try {
            const resp = await apiGetListCompanies({ limit: defaultParams.MAX_LIMIT })
            if (resp.data?.companies === null) {
                return
            }
            const options = resp.data.companies.map((item: ICompany) => ({
                label: item.name,
                value: item.code,
            }))
            setCompanyOptions(options)
        } catch (error) {
            console.log(error)
        }
    }, [])

    const fetchPartnerPackages = useCallback(async () => {
        if (!cpCode) return
        try {
            const resp = await apiGetListPackagesConnection(cpCode)
            if (resp.data?.partner_packages === null) {
                return
            }
            const options = resp.data.partner_packages.map((item: IPartnerPackage) => ({
                label: item.name,
                value: item.code,
                cpCode: item.cpCode,
            }))
            setPartnerPackageOptions(options)
        } catch (error) {
            console.log(error)
        }
    }, [cpCode])

    useEffect(() => {
        fetchTransactionConnection()
        fetchCompanies()
        fetchPartnerPackages()
    }, [fetchTransactionConnection, fetchCompanies, fetchPartnerPackages])

    function checkSyntax(syntax: string) {
        const syntaxParts = syntax.split(rSpace)
        // 2 -> 4: (PhoneAgency) PhoneSim SerialSim (PhoneCustomer)
        if (syntaxParts.length < 2 || syntaxParts.length > 4) {
            return false
        }
        // PhoneAgency/PhoneSim
        if (!rPhoneSim.test(syntaxParts[0]) && !rPhoneNumber.test(syntaxParts[0])) {
            return false
        }
        // PhoneSim/SerialSim
        if (!rPhoneSim.test(syntaxParts[1]) && !rSerialSim.test(syntaxParts[1])) {
            return false
        }
        // SerialSim/PhoneCustomer
        if (syntaxParts.length > 2 && !rSerialSim.test(syntaxParts[2]) && !rPhoneNumber.test(syntaxParts[2])) {
            return false
        }
        // PhoneCustomer
        if (syntaxParts.length > 3 && !rPhoneNumber.test(syntaxParts[3])) {
            return false
        }
        return true
    }

    function processSyntax(syntax: string) {
        const tranInfo = {
            phoneAgency: null,
            phoneSim: null,
            serialSim: null,
            phoneCustomer: null,
        } as any
        const syntaxParts = syntax.split(rSpace)
        // PhoneAgency/PhoneSim
        if (rPhoneNumber.test(syntaxParts[0])) {
            tranInfo.phoneAgency = syntaxParts[0]
        }
        if (rPhoneSim.test(syntaxParts[0])) {
            tranInfo.phoneSim = syntaxParts[0]
        }
        // PhoneSim/SerialSim
        if (rPhoneSim.test(syntaxParts[1])) {
            tranInfo.phoneSim = syntaxParts[1]
        }
        if (rSerialSim.test(syntaxParts[1])) {
            tranInfo.serialSim = syntaxParts[1]
        }
        if (syntaxParts.length === 2) {
            return tranInfo
        }
        // SerialSim/PhoneCustomer
        if (rSerialSim.test(syntaxParts[2])) {
            tranInfo.serialSim = syntaxParts[2]
        }
        if (rPhoneNumber.test(syntaxParts[2])) {
            tranInfo.phoneCustomer = syntaxParts[2]
        }
        if (syntaxParts.length === 3) {
            return tranInfo
        }
        // PhoneCustomer
        if (rPhoneNumber.test(syntaxParts[3])) {
            tranInfo.phoneCustomer = syntaxParts[3]
        }
        return tranInfo
    }

    const handleConnect = async (values: any) => {
        if (typeConnect !== 1) {
            return
        }

        if (!checkSyntax(values.syntax)) {
            toastError('Cú pháp không chính xác!')
            return
        }

        const tranInfo = processSyntax(values.syntax)
        if (!tranInfo.phoneSim || !tranInfo.serialSim) {
            toastError('Cú pháp không chính xác!')
            return
        }

        try {
            setIsLoading(true)
            const resp = await apiAddTransactionConnection({
                cpCode: values.cpCode,
                dataPack: values.dataPack,
                phoneAgency: tranInfo.phoneAgency,
                phoneSim: tranInfo.phoneSim,
                serialSim: tranInfo.serialSim,
                phoneCustomer: tranInfo.phoneCustomer,
            })
            if (resp.status === 200) {
                toastSuccess('Thêm đấu nối mới thành công!')
                formConnect.setFieldValue('dataPack', '')
                formConnect.setFieldValue('syntax', '')
                fetchTransactionConnection()
            } else {
                toastError('Có lỗi xảy ra!')
            }
        } catch (error) {
            console.log(error)
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }

    const handleUploadFile = async () => {
        if (!fileExcel) {
            toastError('Vui lòng nhập đầy đủ thông tin!')
            return
        }

        try {
            setIsLoading(true)
            const formData = new FormData()
            formData.append("file", fileExcel.file)
            toastSuccess('Nạp file excel thành công!')
            setFileExcel({})
            setDataExcel(null)
            setFileList([])
            formConnect.resetFields()
        } catch (error) {
            console.log(error)
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }

    const checkUploadFile = (file: any) => {
        const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel'
        if (!isExcel) {
            message.error('Vui lòng upload file dạng excel!')
            return Upload.LIST_IGNORE
        }
        return false
    }

    const handleChangeFile = (value: any) => {
        if (value.fileList?.length > 0) {
            handleFileUpload(value.file)
        }
        setFileList(value.fileList)
        setFileExcel(value)
    }

    const handleFileUpload = (file: any) => {
        const reader = new FileReader()
        reader.onload = (event) => {
            const workbook = XLSX.read(event.target?.result, { type: 'binary' })
            const sheetName = workbook.SheetNames[0]
            const sheet = workbook.Sheets[sheetName]
            const sheetData = XLSX.utils.sheet_to_json(sheet)
            setDataExcel(sheetData)
        }

        reader.readAsBinaryString(file)
    }

    const listColumnExcel = dataExcel?.length > 0 ? Object.keys(dataExcel[0])?.map(key => {
        return ({
            key: key,
            title: key,
            dataIndex: key,
        })
    }) : []

    const previewExcelColumns: TableProps<any>['columns'] = [
        { key: 'id', title: 'STT', render: (_text, _record, index) => index + 1 },
        ...listColumnExcel
    ]

    const handleTopupOrderId = async () => {
        try {
            setIsLoading(true)
            toastSuccess('Đấu nối thành công!')
            setModalStatus({ data: null, isOpen: false })
        } catch (error) {
            console.log(error);
            toastError('Có lỗi xảy ra!')
        } finally {
            setIsLoading(false)
        }
    }

    const handleCloseModalTopupOrderId = () => {
        setModalStatus({ data: null, isOpen: false })
    }

    const handleFilterPartnerPackage = (value: string) => {
        formConnect.setFieldValue('dataPack', '')
        setCpCode(value)
    }

    return (
        <div>
            <Form form={formConnect} onFinish={typeConnect === 1 ? handleConnect : handleUploadFile}>
                <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
                    <Form.Item style={{ flex: 1 }} name='type' label='Loại đấu nối'>
                        <Select
                            disabled
                            value={typeConnect}
                            onChange={(value) => setTypeConnect(value)}
                            defaultValue={1}
                            options={[
                                { label: 'Đấu nối đơn', value: 1 },
                                { label: 'Đấu nối theo lô', value: 2 },
                            ]} />
                    </Form.Item>
                    <Form.Item style={{ flex: 1 }} name='cpCode' label='Công ty'
                        rules={[{ required: true, message: 'Vui lòng chọn công ty!' }]}>
                        <Select
                            onChange={handleFilterPartnerPackage}
                            style={{ width: '100%' }}
                            showSearch
                            options={companyOptions}
                            filterOption={filterOptionByLabel}
                        />
                    </Form.Item>
                </div>
                {typeConnect === 1 ? (
                    <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
                        <Form.Item name="dataPack" label="Gói cước"
                            style={{ flex: 1 }}
                            rules={[{ required: true, message: 'Vui lòng chọn gói cước!' }]}>
                            <Select
                                style={{ width: '100%' }}
                                showSearch
                                options={partnerPackageOptions}
                                filterOption={filterOptionByLabel}
                            />
                        </Form.Item>
                        <Form.Item name="syntax" label="Cú pháp"
                            style={{ flex: 2 }}
                            rules={[{ required: true, message: 'Vui lòng nhập cú pháp!' }]}>
                            <Input autoComplete="off" />
                        </Form.Item>
                    </div>
                ) : (
                    <>
                        <Form.Item name='file' label={<span>Đăng tải File Excel ( {<Link to={""}>Template</Link>} )</span>}
                            tooltip={<div>
                                <p style={{ fontSize: 10 }}><b>Định dạng:</b> Msisdn, SerialSim, </p>
                                <p style={{ fontSize: 10 }}><b>Tên sheet:</b> Sheet1, </p>
                                <p style={{ fontSize: 10 }}><b>Tối đa:</b> 1.000 sim</p>
                            </div>}
                            rules={[{ required: true, message: 'Vui lòng đăng tải file Excel!' }]}>
                            <Space style={{ display: 'flex' }}>
                                <Upload
                                    maxCount={1}
                                    fileList={fileList}
                                    onChange={handleChangeFile}
                                    beforeUpload={checkUploadFile}
                                >
                                    <Button icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                                {dataExcel && <Button onClick={() => setPreviewFileExcel(true)} type='primary'>Preview</Button>}
                            </Space>
                        </Form.Item>
                        <Form.Item style={{ flex: 1 }} name='description' label='Mô tả'
                            rules={[{ required: true, message: 'Vui lòng nhập mô tả!' }]}>
                            <Input />
                        </Form.Item>
                    </>
                )}
                <div style={{ textAlign: 'center' }}>
                    <Button htmlType="submit" icon={<CheckOutlined />} style={{ background: '#008965', color: 'white' }}>
                        <b>Xác nhận</b>
                    </Button>
                </div>
            </Form>
            <Divider />
            <CustomTable
                namePage='giao dịch'
                columns={transactionConnectionColumns}
                dataSource={transactionConnection.transactions}
                pageSize={pageSize}
                setPageSize={setPageSize}
                total={transactionConnection.total}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <Modal
                title='Preview File Excel'
                open={previewFileExcel}
                onCancel={() => setPreviewFileExcel(false)}
                footer={[]}
            >
                {dataExcel && (
                    <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
                        <CustomTable
                            columns={previewExcelColumns}
                            dataSource={dataExcel}
                            total={dataExcel.length}
                            isHiddenPagination
                        />
                    </div>
                )}
            </Modal>
            <Modal title='Đấu nối' open={modalStatus.isOpen}
                onCancel={handleCloseModalTopupOrderId}
                footer={[
                    <Button onClick={handleTopupOrderId}
                        style={{ background: '#008965', color: 'white' }}>
                        Xác nhận
                    </Button>,
                    <Button onClick={handleCloseModalTopupOrderId}
                        style={{ background: 'gray', color: 'white' }}>
                        Đóng
                    </Button>,
                ]}>
                <Divider />
                <p>Nạp tiền cho đơn hàng <b>{modalStatus.data?.orderId}</b></p>
                <Divider />
            </Modal>
        </div>
    )
}
